<template>
  <div class="application-wrapper">
    <div class="application-nav">
      <div class="text">
        {{ $t('application') }}
        <div class="line"></div>
      </div>
    </div>
    <div class="application-nav-link">
      <div>
        <router-link
          v-for="item in navLink"
          :key="item.prop"
          :to="item.link"
          tag="div"
          class="application-nav-link-router"
        >
          {{ $t(item.prop) }}
        </router-link>
      </div>
    </div>
    <div
      v-if="isLoad && applicationList.length > 0"
      class="router"
    >
      <router-view
        :list="applicationList"
      />
    </div>
  </div>
</template>

<script>
import { configLink } from '@/utils'
import { goGet } from '@/utils'
export default {
  name: 'application',
  metaInfo() {
    return {
      title: this.$t('application')
    }
  },
  data() {
    return {
      isLoad: false,
      navLink: [configLink[1], ...configLink[1].list],
      applicationList: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      goGet('/file/list/web').then(res => {
        this.isLoad = true
        this.applicationList = res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.application-wrapper {
  position: relative;
  .application-nav {
    width: 100%;
    height: 600px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('~@/assets/image/application/nav.jpg') no-repeat center center;
    .text {
      width: calc(1200px - (74px * 2));
      line-height: 60px;
      font-weight: bold;
      color: #FFFFFF;
      font-size: 42px;
      .line {
        width: 69px;
        height: 1px;
        background: #fff;
        margin-top: 28px;
      }
    }
  }
  .application-nav-link {
    border-bottom: 1px solid rgba(225, 225, 225, 1);
    position: sticky;
    top: 70px;
    z-index: 10;
    background: #fff;
    &>div {
      width: 100%;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      .application-nav-link-router {
        margin: 0 24px;
        color: rgba(136, 136, 136, 1);
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: color 0.3s;
        &.router-link-exact-active, &:hover {
          transition: color 0.3s;
          color: rgba(34, 34, 34, 1);
        }
      }
    }
  }
}
</style>
